import { useRef, useState } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import Spline from '@splinetool/react-spline';


export default function App() {
  const ref = useRef()
  return (
    <div ref={ref} className="container">
      {/* <div className="text" >
        COMING SOON. 
      </div> */}
     <Spline scene="https://draft.spline.design/0WqDx4GHuplqVKtQ/scene.spline" />
      {/* <Canvas
        shadows
        frameloop="demand"
        dpr={[1, 2]}
        camera={{ position: [0, 0, 4] }}
        style={{ pointerEvents: 'none' }}
        // In order for two dom nodes to be able to receive events they must share
        // the same source. By re-connecting the canvas to a parent that contains the
        // text content as well as the canvas we do just that.
        onCreated={(state) => state.events.connect(ref.current)}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 10]} angle={0.15} penumbra={1} castShadow shadow-mapSize={[2024, 2024]} />
        <pointLight position={[10, 0, 0]} />
        <Box position={[-1.2, 0, 0]} />
        <Shadows position={[0, 0, -0.5]} />
      </Canvas> */}
    </div>
  )
}

// function Box(props) {
//   const ref = useRef()
//   const [hovered, hover] = useState(false)
//   return (
//     <mesh {...props} castShadow ref={ref} onPointerOver={(event) => hover(true)} onPointerOut={(event) => hover(false)}>
//       <boxGeometry args={[1, 1, 1]} />
//       <meshStandardMaterial color={hovered ? 'white' : 'black'} />
//     </mesh>
//   )
// }

// function Shadows(props) {
//   const { viewport } = useThree()
//   return (
//     <mesh receiveShadow scale={[viewport.width, viewport.height, 1]} {...props}>
//       <planeGeometry />
//       <shadowMaterial transparent opacity={0.1} />
//     </mesh>
//   )
// }
